/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  padding: 0;

  background-color: white !important;
  font-family: Roboto;
  overflow: hidden;
}

.default-text-color {
  color: #4F4F4F;
}

.ta-font {
  font-family: 'ta-mgmt-font', serif !important;
}

:root {
  --avitium: #3964FF;
}

.modal-content {
  border: 0 !important;
}

.chat-search-popover-size {
  width: 380px !important;
  max-width: 380px !important;
  overflow: auto;
}

// Custom scrollbar
.avitium-scrollbar::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.15);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.avitium-scrollbar::-webkit-scrollbar
{
  width: 6px;
  background-color: #F5F5F5;
}

.avitium-scrollbar::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #d2d2d2;
}

.icon-back {
  background-image: url('./assets/icons/arrow-left.svg');
  vertical-align: text-bottom;
  height: 30px !important;
  width: 46px !important;
  background-size: cover;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

.icon-plane-departing {
  background-image: url('./assets/icons/3 departing.svg');
  width: 100% !important;
  height: 30px !important;
  background-size: 100% 100% !important;
  vertical-align: middle;
}

.icon-plane-arriving {
  background-image: url('./assets/icons/4 arriving.svg');
  width: 100% !important;
  height: 27px !important;
  background-size: 100% 100% !important;
  vertical-align: middle;
}

.icon-plane-front {
  background-image: url('./assets/icons/1 plane front.svg');
  vertical-align: text-bottom;
  height: 33px !important;
  width: 33px !important;
  background-size: cover;
}

.icon-all-flights {
  background-image: url('./assets/icons/1 mobile - all flights.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-reports-and-data {
  background-image: url('./assets/icons/4  mobile - reports and data - colored.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-home {
  background-image: url('./assets/icons/7  mobile - home - blau.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-day-of-operation-colored {
  background-image: url('./assets/icons/2 mobile - day of operation- colored.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-day-of-operation-blue {
  background-image: url('./assets/icons/2 mobile - day of operation- blau.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-completed-flights-white {
  background-image: url('./assets/icons/3 mobile - completed flights - white.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-completed-flights-colored {
  background-image: url('./assets/icons/3 mobile - completed flights- colored.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-completed-flights-blue {
  background-image: url('./assets/icons/3 mobile - completed flights- blau.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-chats-and-messages-colored {
  background-image: url('./assets/icons/5  mobile - chats and messages - colored.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-chats-and-messages-blue {
  background-image: url('./assets/icons/5  mobile - chats and messages - blau.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-contact-data-colored {
  background-image: url('./assets/icons/6  mobile - contact data - colored.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.icon-contact-data-blue {
  background-image: url('./assets/icons/6  mobile - contact data - blau.svg');
  vertical-align: text-bottom;
  height: 100px;
  width: 100px;
  background-size: cover;
}

.poppins {
  font-family: "Poppins", serif;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 0.4px;
}

.roboto-font {
  font-family: Roboto, sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
}

.dark-blue {
  color: #002060 !important;
}

.button-blue {
  background-color: #4285F6 !important;
  color: white !important;
}

.user-menu-canvas {
  z-index: 10000 !important;
  height: 380px !important;
}

button {
  font-family: Roboto, sans-serif !important;
}

.modal-backdrop {
  z-index: 20001 !important;
}

.modal-title {
  font-family: "Poppins", Roboto, sans-serif;
}

.modal.show {
  z-index: 20002 !important;
}

.custom-button-1 {
  width: 30% !important;
  height: 30px !important;;
  background-color: #4285F6 !important;
  display: flex !important;;
  justify-content: center !important;;
  color: white !important;
}
.custom-button-1 > span {
  align-self: center !important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

.fw-800 {
  font-weight: 800 !important;
}

.flex-basis-25 {
  flex-basis: 25% !important;
}

.modal-header {
  background-color: white !important;;
  border-bottom: 2px solid #ECF4FE !important;
  color: #002060 !important;
  font-family: 'Poppins', Roboto, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 800 !important;
}

.modal-footer {
  border-top: 2px solid #ECF4FE !important;
  width: 100%;
  align-self: end;
}

.btn-close {
  background: transparent url("/assets/icons/close-x.svg") center/1em auto no-repeat !important;
  opacity: 1 !important;
}

.light-blue-border {
  border-color: #C0D8ED !important;
  border-radius: 5px;
  border: 1px solid;
}
